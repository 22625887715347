<template>
  <div>
    <van-nav-bar title="水表详情" left-arrow @click-left="$router.go(-1)" />
    <div class="bg">
      <div class="page-top">
        <div class="headImg">
          <img src="@/assets/water.png" alt="" />
        </div>
        <div class="d-flex col-center row-center">
          <span class="margin-r-10">{{ info.name }}</span>
          <div class="state-box">
            <span v-if="info.enabled" class="state">
              <van-icon name="passed" />
              <span class="margin-l-2">启用</span>
            </span>
            <span v-else class="state">
              <van-icon name="close" />
              <span class="margin-l-2">禁用</span>
            </span>
          </div>
        </div>
        <div class="d-flex row-center col-center font-12 margin-t-10">
          <div>{{ areaName || "未录入" }}</div>
          <div class="circle"></div>
          <div>{{ info.address || "未录入" }}</div>
        </div>
      </div>
      <div class="page-center d-flex">
        <div class="item">
          <div class="name">用水性质</div>
          <div class="value">{{ useWaterType[info.useWaterType] }}</div>
        </div>
        <div class="item">
          <div class="name">计费方式</div>
          <div class="value">{{ billingType[info.billingType] }}</div>
        </div>
        <div class="item">
          <div class="name">周期</div>
          <div class="value">{{ billingCycleType[info.billingCycleType] }}</div>
        </div>
        <div class="item" v-if="info.billingType === 0">
          <div class="name">单价</div>
          <div class="value">
            {{ $price(info.perPrice) }}
            <span class="text-white font-12">元/吨</span>
          </div>
        </div>
        <div class="item" v-else>
          <div class="name">费用</div>
          <div class="value">
            {{ $price(info.perPrice) }}
            <span class="text-white font-12">元/期</span>
          </div>
        </div>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="系统编号" :value="info.id" />
      <van-cell title="联系电话" :value="info.phone || '未录入'" />
      <van-cell title="备注" :value="info.remark || '无'" />
      <!-- 记录没有上次读数就是用水表创建时写入的上次读数 -->
      <van-cell
        v-if="info.billingType === 0"
        title="上次读数用量"
        :value="recordInfo.dosage || info.lastDosage"
      />
      <van-cell title="上次记录时间" :value="recordInfo.createdTime | Date" />
      <!-- <van-cell title="是否启用" :value="info.enabled ? '启用' : '停用'" /> -->
    </van-cell-group>

    <div class="d-flex row-around padding-y-20">
      <van-button type="info" @click="toPage('add-record')">
        新增记录
      </van-button>
      <van-button type="primary" @click="toPage('used-water-history')">
        查看记录
      </van-button>
      <!-- <van-button type="primary" v-if="isAlive" @click="toPage('edit-record')">
        修改抄表记录
      </van-button> -->
    </div>
    <div class="isIphonex-padding"></div>
  </div>
</template>

<script>
//该页面的内容是水表详情和上次记录的结合
import { getWaterUnit } from "@/apis/water-unit.js";
import { getRecordList } from "@/apis/water-record.js";
import { useWaterType, billingType, billingCycleType } from "@/config/const.js";
import { Toast } from "vant";
export default {
  name: "RecordDetail",
  props: {},
  data() {
    return {
      useWaterType,
      billingType,
      billingCycleType,
      id: "", //水表id
      info: {}, //水表信息
      recordInfo: {}, //记录信息
      isAlive: false, //是否可以修改上次抄表记录
      index: 0,
      toast: null,
      areaName: "",
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.areaName = this.$route.query.areaName;
    this.getPageInfo();
  },
  methods: {
    getPageInfo() {
      this.toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
      });
      // 获取水表详情
      getWaterUnit(this.id).then((res) => {
        if (res.succeeded) {
          this.info = res.data;
        }
        this.clearLoading();
      });
      // 获取上次记录
      getRecordList({
        WaterUnitId: this.id,
        MaxCount: 1,
        SkipCount: 0,
      }).then((res) => {
        if (res.succeeded) {
          if (res.data.items.length > 0) {
            this.recordInfo = res.data.items[0];
          }
        }
        this.clearLoading();
      });

      // 获取最近一次可修改抄表度数
      // getModified(this.id).then((res) => {
      //   this.isAlive = res.succeeded;
      //   this.clearLoading();
      // });
    },
    clearLoading() {
      this.index++;
      if (this.index >= 3) {
        this.toast && this.toast.clear();
      }
    },
    toPage(path, flag) {
      let url = `${path}?id=${this.id}`;
      if (flag) {
        url = url + "&edit=1";
      }
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  padding-top: 20px;
  background-image: linear-gradient(to bottom, #33dec0, #40cfe1);
}
.page-top {
  padding-bottom: 20px;
  color: #fff;
  .headImg {
    width: 100px;
    height: 100px;
    // border: 1px solid #eee;
    margin: 10px auto;
    display: block;
    border-radius: 50%;

    > img {
      width: 100%;
      height: 100%;
    }
  }
  text-align: center;

  .circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 4px;
  }

  .state-box {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 4px;
    border-radius: 2px;
  }
  .state {
    font-size: 12px;
  }
}

.page-center {
  background-color: rgba(0, 0, 0, 0.1);
  .item {
    flex: 1;
    text-align: center;
    border-right: 1px solid #fff;
    padding: 10px 0;
    &:last-child {
      border: none;
    }

    .name {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      margin-bottom: 6px;
    }
    .value {
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
